export interface User extends ApiEntity {
  gender?: string
  email?: string
  initials?: string
  firstName: string
  lastName: string
  fullName: string
  phoneNumber?: string
  locale: string
  avatarUrl?: string
  canTakeOver?: boolean
  // Maybe not applicable to all user types, but we don't have subtypes for
  // different user models yet.
  jobTitle?: string
  // Can this user sign in?
  isActive: boolean
  // Has the user confirmed their email address?
  confirmed: boolean
  // Meta info
  lastSignInAt?: string
  invitedById?: id
  signupMethod?: 'unknown' | 'signed_up' | 'invited' | 'imported'
  canReceiveMessages?: boolean
  profileColor?: string
}

export interface UserWithRolesAndOrganizations extends User {
  roles: Role[]
  organizations: Organization[]
}

export interface Role extends NamedApiEntity {
  resourceId: id
  resourceType: string
}

export interface UserWithRoles extends User {
  roles: Role[]
}

// Fetch user using useJsonApiFetch
// FIXME: we need something like $fetchJsonAp, to fix `nuxt] [useFetch] Component is already mounted, please use $fetch instead` warning
export function fetchUserWithRoles(id: id) {
  return useJsonApiFetch<UserWithRolesAndOrganizations>(`/api/v1/users/${id}?include=roles,organizations`)
}

export function fetchCurrentUser() {
  return useJsonApiFetch<UserWithRoles>('/api/v1/current_user?include=roles')
}

// Fetch user using `useApiFetch`
export async function fetchUser(id: id) {
  const {
    attributes: user,
    data,
    error,
    pending,
    relationships,
    refresh,
  } = await useApiFetch<User, { roles: Role[], organizations: Organization[] }>(
    `/api/v1/users/${id}?include=roles,organizations`,
    {},
    false,
  )

  return { user, relationships, data, error, pending, refresh }
}

export async function fetchUsers(query: any) {
  const {
    attributes: users,
    data,
    pending,
    relationships,
    refresh,
  } = await useApiFetch<User[], { organizations: Organization[] }[]>(
    '/api/v1/users?include=organizations',
    {
      query,
    },
  )
  return { users, relationships, data, pending, refresh }
}

interface CreateUserPayload {
  organizationId: id
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
}

export async function createUser(attributes: CreateUserPayload) {
  const { data: user } = await $api<JSONAPIResponse<User>>('/api/v1/users', {
    method: 'POST',
    body: {
      data: {
        type: 'user',
        attributes: attributes,
      },
    },
  })

  return user
}

interface UpdateUserPayload {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  locale?: string
}

export async function updateCurrentUser(attributes: UpdateUserPayload) {
  const { data: user } = await $api<JSONAPIResponse<User>>(
    '/api/v1/current_user',
    {
      method: 'PATCH',
      body: {
        user: attributes,
      },
    },
  )

  return user
}

export async function deleteUser(id: id) {
  await $api<JSONAPIResponse<User>>(`/api/v1/users/${id}`, {
    method: 'DELETE',
  })
}

export async function updateUser(id: id, attributes: UpdateUserPayload) {
  const { data: user } = await $api<JSONAPIResponse<User>>(
    `/api/v1/users/${id}`,
    {
      method: 'PATCH',
      body: {
        data: {
          type: 'users',
          id,
          attributes,
        },
      },
    },
  )

  return user
}

export async function addRole(
  id: id,
  // TODO: I couldn't fix the type for `role` in a way that makes it match with vee-valdiate's generated types
  // in AddRoleForm. Enum typing stuff... That's why I added the "| string" part.
  payload: { organizationId: id, role: keyof typeof OrganizationRole | string },
) {
  const { data: user } = await $api<JSONAPIResponse<User>>(
    `/api/v1/users/${id}/add_role`,
    {
      method: 'PUT',
      body: {
        data: {
          type: 'users',
          attributes: payload,
        },
      },
    },
  )

  return user
}

// id is the ROLE id, not the user id.
export async function removeRole(
  userId: id,
  payload: {
    organizationId: id
    role: keyof typeof OrganizationRole | string
  },
) {
  await $api<JSONAPIResponse<User>>(`/api/v1/users/${userId}/remove_role`, {
    method: 'DELETE',
    body: {
      data: {
        type: 'users',
        attributes: payload,
      },
    },
  })
}

export async function resendConfirmationInstructions(id: id) {
  await $api<JSONAPIResponse<User>>(`/api/v1/users/${id}/resend_confirmation_instructions`, {
    method: 'POST',
  })
}

export async function confirmUser(token: string) {
  return await $api<{ status: string, resetPasswordToken?: string }>(
    `/api/v1/confirm`,
    {
      method: 'POST',
      body: {
        confirmation_token: token,
      },
    },
  )
}

// Sign in on another user's account.
// The payload can be the id or email.
export async function signInAsUser(payload: { id: id } | { email: string }) {
  const { data: user } = await $api<JSONAPIResponse<User>>(
    `/api/v1/sign_in_as_user`,
    {
      method: 'POST',
      body: {
        data: {
          type: 'user',
          attributes: payload,
        },
      },
    },
  )

  return user
}

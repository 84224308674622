import { deserialise, deattribute } from 'kitsu-core'
import { useFetch } from '#imports'
import { defu } from 'defu'
import { getFetchOptions } from './useApiFetch'
import type { AnymatchPattern } from 'vite'

// Check to determine if a `data` field is part of a JSON:API relationship (so we can safely exclude it)
// @ts-ignore any type for now
function isJsonApiRelationship(obj: any): boolean {
  return obj && typeof obj === 'object' && 'data' in obj
    && (Array.isArray(obj.data) || (obj.data && 'type' in obj.data && 'id' in obj.data))
}

// This removes the 'data' wrapper around the response object, including nested/included objects
// @ts-ignore any type for now
function unnestRelationships(obj: any, visited = new WeakMap()): any {
  if (Array.isArray(obj)) {
    return obj.map(item => unnestRelationships(item, visited))
  }
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // Check if we've already visited this object
  if (visited.has(obj)) {
    return visited.get(obj)
  }

  const result: any = { ...obj }

  // Mark this object as visited
  visited.set(obj, result)

  for (const key in result) {
    if (isJsonApiRelationship(result[key])) {
      result[key] = unnestRelationships(result[key].data, visited)
    }
    else {
      result[key] = unnestRelationships(result[key], visited)
    }
  }

  return result
}

// Add _meta as a property to the deserialized object
export type WithMeta<T> = T extends Array<infer U> ? (T & { _meta: any }) : (T & { _meta: any })

export function deserializeJsonApiData<T>(data: any): WithMeta<T> {
  const deserialised = deserialise(data)
  const transformed = deattribute(deserialised).data
  const unnestTransformed = unnestRelationships(transformed) as T
  const meta = data.meta || {}

  if (Array.isArray(unnestTransformed)) {
    // Add _meta property to the array
    return Object.assign(unnestTransformed, { _meta: meta }) as WithMeta<T>
  }
  else {
    // Add _meta property to the object
    return { ...unnestTransformed, _meta: meta } as WithMeta<T>
  }
}

// TODO: fix the return type: wrapping T in WithMeta<T> is what we want to achieve
export function useJsonApiFetch<T>(url: string, options?: any) {
  const defaultOptions = getFetchOptions<T>(url)

  // Use unjs/defu for nice deep defaults
  const mergedOptions = defu({}, options, defaultOptions)

  return useFetch<T>(url, {
    ...mergedOptions,
    headers: {
      // @ts-ignore (options has type any, which is causing type issues. I'm too lazy to fix that.)
      ...mergedOptions.headers,
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    transform: (data) => {
      if (data) {
        return deserializeJsonApiData<T>(data) as any // TODO: fix typing
      }
      return data
    },
  })
}

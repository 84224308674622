export default defineNuxtRouteMiddleware(async (to) => {
  const { isAuthenticated } = useAuth()

  // Pages that have a meta flag of isPublic don't require authentication
  if (!to.meta.isPublic && !isAuthenticated.value) {
    console.error(`🙅‍♀️ User not authenticated.`)
    return navigateTo('/auth/login-1')
  }

  // If the user is authenticated, populate the user and currentOrganization stores.
  // We're doing this in a middleware to cover two scenarios:
  // a) After signing in and doing a redirect
  // b) On initial page load with a valid access token (valid auth status)
  if (isAuthenticated.value) {
    const currentUserStore = useCurrentUserStore()
    if (!currentUserStore.currentUser) {
      // console.log(`[auth middleware] Fetching current user from store`)
      await currentUserStore.fetch()
    }

    // If the current user store failed, it's probably due to an expired token
    // If we want this to be more rebust/precise, we should check for a 401 response,
    // but for now this will probably cover most scenarios.
    if (currentUserStore.failed) {
      // console.log(`[auth middleware] User store failed, signing out`)
      await currentUserStore.signOut()
      return navigateTo('/auth/login-1')
    }

    // console.log(`[auth middleware] Initializing organizations store`)
    const currentOrganizationStore = useCurrentOrganizationStore()
    await currentOrganizationStore.initialize()
    if (currentOrganizationStore.failed) {
      return showError(
        createError({
          statusCode: 403,
          statusMessage: 'Geen organisatie gevonden',
          fatal: true,
        }),
      )
    }
  }
})

export const useCurrentOrganizationStore = defineStore(
  'currentOrganization',
  () => {
    const { captureException } = useSentry()

    // State
    const userOrganizations = ref<Organization[]>([])
    // The selected organization Id. Persisted.
    const selectedOrganizationId = ref<id | undefined>(undefined)
    // The selected organization, but not guaranteed to be set.
    const selectedOrganization = computed(() => userOrganizations.value.find(o => o.id == selectedOrganizationId.value))

    // `currentOrganization` is non-null version of `selectedOrganization` for context where we know the user is signed in.
    // This is the cousing of `currentUser` in the currentUserStore.
    const currentOrganization = computed(() => selectedOrganization.value!)
    // Statusses
    const loading = ref(false)
    const initialized = ref(false)
    const failed = ref(false)
    // Error(s)
    // const error = ref<Error | null>(null)

    // Actions
    async function fetch() {
      loading.value = true
      try {
        // TODO: let `fetchUserOrganizations` unwrap the response
        const { data } = await fetchUserOrganizations()
        userOrganizations.value = data.map(resource => resource.attributes)
        selectFirstOrganization()
      }
      catch (err: any) {
        console.error(
          '[currentOrganizationStore] Failed to fetch user organizations:',
        )
        captureException(err)
        // This was causing Nuxt crashes. Not exactly sure why, something with error serialization.
        // error.value = err
        failed.value = true
      }
      finally {
        loading.value = false
      }
    }

    async function initialize() {
      if (initialized.value) return
      await fetch()
      initialized.value = !failed.value
    }

    function selectFirstOrganization() {
      // Prioritize organizations of type School or VolunteerCenter.
      const prioritizedTypes = ['School', 'VolunteerCenter']

      if (userOrganizations.value.length && !selectedOrganizationId.value) {
        const prioritizedOrganization = userOrganizations.value.find(o => prioritizedTypes.includes(o.type))
        selectedOrganizationId.value = prioritizedOrganization?.id || userOrganizations.value[0].id
      }

      // Clear selected organization if it's not in the list
      if (selectedOrganizationId.value && !userOrganizations.value.find(o => o.id == selectedOrganizationId.value)) {
        selectedOrganizationId.value = undefined
      }
    }

    // Clear on sign out
    const { user } = storeToRefs(useCurrentUserStore())
    watch(user, (user) => {
      if (!user) {
        userOrganizations.value = []
        loading.value = false
        failed.value = false
        initialized.value = false
        selectedOrganizationId.value = undefined
      }
    })

    // Return store API
    return {
      // State
      userOrganizations,
      selectedOrganizationId,
      selectedOrganization, // computed property
      currentOrganization, // computed property
      loading,
      initialized,
      failed,
      // Actions
      initialize,
      fetch,
    }
  },
  {
    persist: {
      paths: ['selectedOrganizationId'],
    },
  },
)

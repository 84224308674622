import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/.app/middleware/auth.global.ts";
import handen_45redirect_45global from "/vercel/path0/volunteerly/middleware/handen-redirect.global.ts";
import preload_45global from "/vercel/path0/volunteerly/middleware/preload.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  handen_45redirect_45global,
  preload_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}
import DOMPurify from 'dompurify'

// Source: https://blog.hyperlink.fi/using-dompurify-in-nuxt3
export default defineNuxtPlugin(async (nuxtApp) => {
  let sanitize
  if (import.meta.server) {
    const { JSDOM } = await import('jsdom')
    sanitize = DOMPurify(new JSDOM('').window).sanitize
  }
  else {
    sanitize = DOMPurify.sanitize
  }

  return {
    provide: {
      sanitizeHTML: sanitize,
    },
  }
})

export interface VolunteerCenter extends Omit<Organization, 'status'> {
  municipality: string
}

export interface VolunteerCenterProfile {
  websiteLogoUrl?: string
  faviconUrl?: string
  municipality?: string
  primaryColor?: string
  mutedColor?: string
  colorShades?: string
  facebookUrl?: string
  footerColumn1Title?: string
  footerColumn2Title?: string
  footerColumn3Title?: string
  footerColumn4Title?: string
  footerColumn6Title?: string
  footerColumn6Content?: string
  footerColumn8Title?: string
  footerColumn8Content?: string
  showAnbiLogo?: boolean
  showNovLogo?: boolean
  instagramUrl?: string
  mastodonUrl?: string
  tiktokUrl?: string
  xUrl?: string
  gtagId?: string
}

export async function fetchVolunteerCenters() {
  const {
    attributes: volunteerCenters,
    pending,
    refresh,
    data,
  } = await useApiFetch<VolunteerCenter[]>('/api/v1/volunteer_centers')

  return {
    volunteerCenters,
    pending,
    refresh,
    data,
  }
}

export async function fetchCurrentVolunteerCenter() {
  const {
    attributes: volunteerCenter,
    relationships,
    pending,
    refresh,
    data,
  } = await useApiFetch<VolunteerCenter, { profile: VolunteerCenterProfile }>(
    '/api/v1/volunteer_centers/current?include=profile',
  )

  return {
    volunteerCenter,
    relationships,
    pending,
    refresh,
    data,
  }
}

// This middleware preloads a few queries that are otherwise being triggered multiple times
// during the initial page load.
export default defineNuxtRouteMiddleware(async (to, from) => {
  // Only run this on the server.
  // That way we make sure it only happens on the initial page load.
  if (process.client) return

  // console.log(`[preload middleware] from`, from.path)
  // console.log(`[preload middleware] to ${to.path}`)
  const { authStatus } = useAuth()

  if (authStatus.value == AuthStatus.SignedIn) {
    // Prepopulate the cache
    await fetchCurrentUser()
  }

  // console.log(`[preload middleware] preloading...`)
  await fetchMenus()
  await fetchMenuItems()
  // await fetchCurrentVolunteerCenter()
  // console.log(`[preload middleware] done preloading`)
})

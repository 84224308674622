import { type CookieRef } from 'nuxt/app'

// This setup is a bit messy, because at the moment the SOT for auth are the three cookies:
// access-token, client, and uid. This composable currently contains both the logic for
// handling response headers with the cookies, and the logic for setting the cookie + auth state.

export enum AuthStatus {
  SignedOut = 'SIGNED_OUT',
  SignedIn = 'SIGNED_IN',
}

const mapCookiesToHeaders = ({
  accessTokenCookie,
  clientCookie,
  uidCookie,
}: {
  accessTokenCookie: CookieRef<string | null | undefined>
  clientCookie: CookieRef<string | null | undefined>
  uidCookie: CookieRef<string | null | undefined>
}) => {
  return {
    ...(accessTokenCookie.value
    && clientCookie.value
    && uidCookie.value && {
      'access-token': accessTokenCookie.value,
      'client': clientCookie.value,
      'uid': uidCookie.value,
    }),
  }
}

export const useAuth = () => {
  const cookieOptions = { secure: true, watch: true }

  const accessTokenCookie = useStatefulCookie('access-token', cookieOptions)
  const clientCookie = useStatefulCookie('client', cookieOptions)
  const uidCookie = useStatefulCookie('uid', cookieOptions)

  const authStatus = computed(() => {
    return accessTokenCookie.value && clientCookie.value && uidCookie.value
      ? AuthStatus.SignedIn
      : AuthStatus.SignedOut
  })

  const authHeaders = computed(() => {
    return mapCookiesToHeaders({
      accessTokenCookie,
      clientCookie,
      uidCookie,
    })
  })

  const isAuthenticated = computed(() => {
    return authStatus.value == AuthStatus.SignedIn
  })

  // watch(authHeaders, (authHeaders) => {
  //   console.log(`[useAuth] 🍪 authHeaders changed`, authHeaders)
  // })

  // Update cookie values based on response headers
  const handleAuthResponseHeaders = (responseHeaders: Headers) => {
    // console.log(`[useAuth] 🍪 handleAuthResponseHeaders --`)
    const accessToken = responseHeaders.get('access-token')
    const client = responseHeaders.get('client')
    const uid = responseHeaders.get('uid')
    // console.log(
    //   `[useAuth] 🍪 Response headers: ${accessToken}, ${client}, ${uid}`,
    // )

    if (accessToken && client && uid) {
      accessTokenCookie.value = accessToken
      clientCookie.value = client
      uidCookie.value = uid
      // console.log(`[useAuth] 🍪 Set auth cookies. Token ${accessToken}`)
    }
    else {
      // console.log(`[useAuth] 🥠 No auth cookies returned from response`)
    }
  }

  const signOut = async () => {
    try {
      // console.log(`[useAuth] Signing out on API`)
      await signoutUser()
      // console.log(`[useAuth] Signed out on API`)
    }
    catch {
      console.log(
        `[useAuth] 🔑 Failed to sign out.`,
      )
    }
    console.log(`[useAuth] 🔑 Signing out...`)
    accessTokenCookie.value = ''
    clientCookie.value = ''
    uidCookie.value = ''
    console.log(`[useAuth] 🔑 Cleared cookies...`)
  }

  return {
    accessToken: accessTokenCookie,
    client: clientCookie,
    uid: uidCookie,
    authStatus,
    authHeaders,
    handleAuthResponseHeaders,
    signOut,
    isAuthenticated,
  }
}

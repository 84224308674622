import { ref, computed } from 'vue'

export default function useCookieConsent() {
  // Be aware that changes to consentCookie.value won't automatically update the individual refs.
  const consentCookie = useCookie<{ functional: boolean, analytical: boolean | undefined, thirdParty: boolean | undefined }>('cookieConsent', {
    default: () => ({
      functional: true,
      // undefined means that the user hasn't made a choice yet, so we'll show the banner
      analytical: undefined,
      thirdParty: undefined,
    }),
    maxAge: 365 * 24 * 60 * 60,
  })

  // Individual refs for each cookie type. Fallback to true in case the default value of useCookie doesn't work as expected.
  const functionalCookies = ref(consentCookie.value?.functional ?? true)
  const analyticalCookies = ref<boolean | undefined>(consentCookie.value?.analytical)
  const thirdPartyCookies = ref<boolean | undefined>(consentCookie.value?.thirdParty)

  const hasUserMadeChoice = computed(() =>
    !(consentCookie.value === undefined || consentCookie.value.analytical === undefined || consentCookie.value.thirdParty === undefined),
  )

  // State for showing the banner
  const showBanner = ref(hasUserMadeChoice.value === false ? true : false)
  // State for showing the settings modal (not in use yet)
  const showSettings = ref(false)

  const acceptedCookies = computed(() => ({
    functional: functionalCookies.value,
    analytical: analyticalCookies.value,
    thirdParty: thirdPartyCookies.value,
  }))

  const acceptAllCookies = () => {
    functionalCookies.value = true
    analyticalCookies.value = true
    thirdPartyCookies.value = true
    saveSettings()
  }

  const rejectAllCookies = () => {
    functionalCookies.value = true // Keep functional cookies
    analyticalCookies.value = false
    thirdPartyCookies.value = false
    saveSettings()
  }

  const openSettings = () => {
    showSettings.value = true
  }

  const saveSettings = async () => {
    const consentData = {
      functional: functionalCookies.value,
      analytical: analyticalCookies.value ?? false,
      thirdParty: thirdPartyCookies.value ?? false,
    }
    consentCookie.value = consentData

    // Update Google Consent Mode
    const { gtag } = useGtag()
    gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: analyticalCookies.value === true ? 'granted' : 'denied',
    })

    showBanner.value = false
    showSettings.value = false
  }

  return {
    showBanner,
    showSettings,
    functionalCookies,
    analyticalCookies,
    thirdPartyCookies,
    acceptAllCookies,
    rejectAllCookies,
    openSettings,
    saveSettings,
    acceptedCookies,
    hasUserMadeChoice,
  }
}

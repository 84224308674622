import type { CookieOptions, CookieRef } from '#app'
import { useCookie, useState } from '#imports'
import { watch, type WatchCallback } from 'vue'

/**
 * This composable was supposed to be unnecesary since this is merged: https://github.com/nuxt/nuxt/issues/13020#issuecomment-1397282717
 * However, I ran into a weird async behavior or some kind of race condition, where a cookie was "reset" to
 * it's previous value. Therefore I implemented a lock mechanism.
 * If you change this, make sure you test browser tab sync behavior properly, and beware of any timing issues.
 */
export function useStatefulCookie<T = string | null>(
  name: string,
  options?: CookieOptions<T>,
): CookieRef<T> {
  const useLock = true // We could make this configureable, if necessary.
  const cookie = useCookie(name, options)
  const state = useState(name, () => cookie.value)

  const isLocked = useState<boolean>(name + '_locked', () => false)

  const callback: WatchCallback<T, T> = () => {
    // Set the lock
    isLocked.value = useLock ? true : false
    // Unlock after a short delay
    setTimeout(() => {
      isLocked.value = false
    }, 100)

    cookie.value = state.value
  }

  // Watch the cookie for changes from other tabs
  watch(
    cookie,
    (newCookieValue) => {
      if (!isLocked.value && newCookieValue !== state.value) {
        // console.log(
        //   `[useStatefulCookie][${name}] received cookie change`,
        //   newCookie,
        // )
        state.value = newCookieValue
      }
    },
    { deep: true },
  )

  watch(state, callback, { deep: true })

  return state
}

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useCookieConsent from '../composables/useCookieConsent'

const { t } = useI18n()

const {
  showBanner,
  // showSettings,
  functionalCookies,
  analyticalCookies,
  thirdPartyCookies,
  acceptAllCookies,
  rejectAllCookies,
  saveSettings,
} = useCookieConsent()

const hasPartialSelection = computed(() =>
  (analyticalCookies.value && !thirdPartyCookies.value) || (!analyticalCookies.value && thirdPartyCookies.value),
)

function toggleAnalyticalCookies() {
  analyticalCookies.value = !analyticalCookies.value
}

function toggleThirdPartyCookies() {
  thirdPartyCookies.value = !thirdPartyCookies.value
}
</script>

<template>
  <div v-if="showBanner" class="bg-primary-800 fixed bottom-0 z-50 w-full p-4 text-white shadow-md">
    <Container>
      <div class="flex flex-col space-y-4">
        <div>
          <BaseHeading
            as="h3"
            size="xl"
            class="my-4"
          >
            {{ t('cookieConsent') }}
          </BaseHeading>
          <BaseParagraph>
            {{ t('bannerText') }}
          </BaseParagraph>
        </div>

        <div class="flex flex-col space-y-2">
          <div>
            <label class="flex items-center">
              <input
                v-model="functionalCookies"
                type="checkbox"
                disabled
                class="mr-2"
                checked
              >
              {{ t('functionalCookies') }}
            </label>
            <BaseParagraph class="text-sm opacity-70">
              {{ t('functionalCookiesDescription') }}
            </BaseParagraph>
          </div>
          <div>
            <label class="flex cursor-pointer items-center">
              <input
                v-model="analyticalCookies"
                type="checkbox"
                class="mr-2"
              >
              {{ t('analyticalCookies') }}
            </label>
            <BaseParagraph class="cursor-pointer text-sm opacity-70" @click="toggleAnalyticalCookies">
              {{ t('analyticalCookiesDescription') }}
            </BaseParagraph>
          </div>
          <div>
            <label class="flex cursor-pointer items-center">
              <input
                v-model="thirdPartyCookies"
                type="checkbox"
                class="mr-2"
              >
              {{ t('thirdPartyCookies') }}
            </label>
            <BaseParagraph class="cursor-pointer text-sm opacity-70" @click="toggleThirdPartyCookies">
              {{ t('thirdPartyCookiesDescription') }}
            </BaseParagraph>
          </div>

          <div class="flex justify-between space-x-4 py-4">
            <BaseButton
              v-if="!hasPartialSelection"
              color="success"
              @click="acceptAllCookies"
            >
              {{ t('acceptAll') }}
            </BaseButton>
            <BaseButton
              v-else
              color="success"
              @click="saveSettings"
            >
              {{ t('saveSelection') }}
            </BaseButton>
            <BaseButton color="danger" @click="rejectAllCookies">
              {{ t('rejectAll') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "cookieConsent": "Cookie Consent 🍪",
    "functionalCookies": "Functional Cookies",
    "analyticalCookies": "Analytical Cookies",
    "thirdPartyCookies": "Third Party Cookies",
    "acceptAll": "Accept all",
    "rejectAll": "Reject all",
    "settings": "Settings",
    "saveSelection": "Save selection",
    "bannerText": "This website uses cookies that are necessary for the site to function properly. Below you can indicate which other types of cookies you want to accept.",
    "functionalCookiesDescription": "Cookies that are necessary for the website to function properly (always enabled).",
    "analyticalCookiesDescription": "Cookies that allow us to count visits and traffic sources so we can measure and improve the performance of our site.",
    "thirdPartyCookiesDescription": "Cookies that are placed by loading videos from Youtube and other external websites."
  },
  "nl": {
    "cookieConsent": "Cookie toestemming 🍪",
    "functionalCookies": "Functionele cookies",
    "analyticalCookies": "Analytische cookies",
    "thirdPartyCookies": "Cookies van derden",
    "acceptAll": "Accepteer alles",
    "rejectAll": "Alles weigeren",
    "settings": "Instellingen",
    "saveSelection": "Selectie opslaan",
    "bannerText": "Deze website maakt gebruik van cookies die noodzakelijk zijn om deze site zo goed mogelijk te laten functioneren. Hieronder kan je aangeven welke andere soorten cookies je wilt accepteren.",
    "functionalCookiesDescription": "Cookies die ervoor zorgen dat deze website naar behoren functioneert (altijd ingeschakeld).",
    "analyticalCookiesDescription": "Cookies waarmee wij het gebruik van de website kunnen meten. Gegevens die met deze cookies worden verzameld, worden gecombineerd en geaggregeerd ten behoeve van statistische analyse.",
    "thirdPartyCookiesDescription": "Cookies die geplaatst worden door het inladen van filmpjes van Youtube en andere externe websites."
  }
}
</i18n>

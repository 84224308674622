import type { LocaleObject } from 'yup'
import { setLocale as setYupLocale } from 'yup'
import { nl } from 'yup-locales'

// any of the major languages we want to support can be imported from yup-locales
// but since english is the default Yup locale, english is not exported by the yup-locales package
// this is why we need to manually pass the matching english errors here
const englishMessages = {
  mixed: {
    default: '${path} is invalid.',
    required: '${path} is a required field.',
    oneOf: '${path} must be one of the following values: ${values}.',
    notOneOf: '${path} must not be one of the following values: ${values}.',
    notType: '${path} is invalid.', // Not used in Yup.string() or Yup.number()
  },
  string: {
    length: '${path} must be exactly ${length} characters.',
    min: '${path} must be at least ${min} characters.',
    max: '${path} must be at most ${max} characters.',
    matches: '${path} must match the following: "${regex}".',
    email: '${path} must be a valid email.',
    url: '${path} must be a valid URL.',
    trim: '${path} must be a trimmed string.',
    lowercase: '${path} must be a lowercase string.',
    uppercase: '${path} must be an uppercase string.',
  },
  number: {
    min: '${path} must be greater than or equal to ${min}.',
    max: '${path} must be less than or equal to ${max}.',
    lessThan: '${path} must be less than ${less}.',
    moreThan: '${path} must be greater than ${more}.',
    notEqual: '${path} must not be equal to ${notEqual}.',
    positive: '${path} must be a positive number.',
    negative: '${path} must be a negative number.',
    integer: '${path} must be an integer.',
  },
  date: {
    min: '${path} field must be later than ${min}.',
    max: '${path} field must be at or before ${max}.',
  },
  boolean: {},
  object: {
    noUnknown:
      '${path} field cannot have keys not specified in the object shape.',
  },
  array: {
    min: '${path} field must have at least ${min} items.',
    max: '${path} field must have less than or equal to ${max} items.',
  },
}

const yupLocaleMap = new Map([
  // we can extend this by attending more custom locales
  ['nl', nl],
  ['en', englishMessages],
])

const changeYupLocale = (locale: string) => {
  const localeObject = yupLocaleMap.get(locale) as LocaleObject

  setYupLocale(localeObject)
}

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp()
  nuxtApp.$i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    changeYupLocale(newLocale)
    return Promise.resolve()
  }

  // Set the initial locale on app load
  changeYupLocale(nuxtApp.$i18n.locale.value)
})

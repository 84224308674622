<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts'

const route = useRoute()

const { captureException } = useSentry()

// The try-catch here is to fix errors popping up when running tests.
// I'm not sure why app.vue is loaded in every test run, but apparently it is.
// TODO: figure out how to avoid this.
try {
  const { currentCenter, currentCenterProfile } = await useCurrentCenter()

  await useCenterFavicon()

  // Switching colors is not possible on the server yet, therefore doing this in an onBeforeMount hook.
  // See TODO note in switchColorPreset.
  onBeforeMount(() => {
    const { initialize } = useGtag()
    if (currentCenterProfile.value.gtagId) {
      // console.log('[app.vue] Initializing gtag with ID:', currentCenterProfile.value.gtagId)
      initialize(currentCenterProfile.value.gtagId)
    }

    try {
      const colorShades = currentCenterProfile.value?.colorShades
      const primaryColor = currentCenterProfile.value?.primaryColor
      const mutedColor = currentCenterProfile.value?.mutedColor

      if (colorShades) {
        applyUserColorShades(colorShades, 'primary')
      }
      else if (primaryColor) {
        switchColorPreset('primary', primaryColor)
      }
      if (mutedColor) {
        switchColorPreset('muted', mutedColor)
      }
    }
    catch (error) {
      captureException(error)
    }
  })

  /**
   * Global head configuration
   * @see https://unhead.harlanzw.com/
   * @see https://github.com/unjs/unhead/tree/main
   */
  useHead({
    title: route.meta.title || '',
    titleTemplate: (titleChunk) => {
      return titleChunk
        ? `${titleChunk} - ${currentCenter.value.name}`
        : `${currentCenter.value.name}`
    },
    // link: [
    //   {
    //     rel: 'icon',
    //     type: 'image/png',
    //     href: '/img/favicon.png',
    //   },
    // ],

    // See Tairo demo app for examples
    // meta: [],
  })
}
catch (error) {
  // Silence errors in tests
  if (process.env.NODE_ENV != 'test') {
    console.error(error)
  }
}

const { authStatus } = useAuth()

const useDifferentLayouts = true
const publicLayout = 'public'
const defaultLayout = publicLayout // or: 'topnav' | 'sidebar'

// Which doesn't seem to occur when using a timeout
const layout = ref<LayoutKey>(
  authStatus.value == 'SIGNED_IN' && useDifferentLayouts
    ? defaultLayout
    : publicLayout,
)

watch(authStatus, (status) => {
  if (status == 'SIGNED_IN' && useDifferentLayouts) {
    layout.value = defaultLayout
  }
  else {
    layout.value = publicLayout
  }
})

const router = useRouter()

// When a layout is defined on a page, it should take preference of any other layout
const layoutOverride = ref<LayoutKey | false | undefined>(
  route.meta.layout as LayoutKey | undefined,
)
router.afterEach((to) => {
  if (to.meta.layout != layoutOverride.value) {
    layoutOverride.value = to.meta.layout as LayoutKey | undefined
  }
})

// See https://masteringnuxt.com/blog/nuxt-3-client-side-error-handling
// const recoverFromError = async (error: any) => {
//   await navigateTo('/')
//   error.value = null
// }

// Used to trigger full page rerenders when switches organizations
// Mainly done because most form inits aren't fully reactive.
// I guess doing a redirect would be a bit safer, since the user can still
// end up on a page that shouldn't be accessible for the current context.
const { selectedOrganization } = storeToRefs(useCurrentOrganizationStore())
</script>

<template>
  <div>
    

    <NuxtLayout :name="layoutOverride || layout">
      <NuxtLoadingIndicator color="var(--color-primary-500)" />
      
      
      <NuxtErrorBoundary>
        <NuxtPage :key="selectedOrganization?.id" />
        <template #error="{ error }">
          <TairoError
            :error="error"
            @clear-error="() => (error.value = null)"
          />
        </template>
      </NuxtErrorBoundary>
    </NuxtLayout>
    <CookieConsent />
  </div>
</template>

/**
 * Returns the volunteer center id of the website that the user is currently visiting.
 * This is based on the runtime config, which is using an ENV var. However, to also support switching
 * centers (for example while developing), we store the id in a stateful cookie.
 * TODO: Not sure if center switching is still necessary. Maybe for a superuser future? Otherwise maybe we should remove it.
 * This was also causing issues for meedoen. That's why it has been overwritten for meedoen, for example.
 *
 * An alternative approach would be to query the API for the current center by hostname. That would require
 * less config work and more scalable if we want to automate client/tenant creation, but also more error-prone,
 * for example, when someone changes their URL in the settings.
 */
export const useCurrentCenterId = () => {
  const volunteerCenterId = useCookie<id>('volunteerCenterId', {
    secure: true,
    watch: true,
  })

  function setInitialCenterId() {
    // Get current hostname
    const host = useRequestURL().host

    // Map hostname to ID
    const publicConfig = useRuntimeConfig().public
    const volunteerCenterIdsByHostname
    = publicConfig.volunteerCenterIds as Record<string, id>

    const initialVolunteerCenterId
    = volunteerCenterIdsByHostname[host] || publicConfig.defaultVolunteerCenterId

    if (initialVolunteerCenterId === undefined) {
    // TODO: Report to Sentry
      console.error(
      `volunteerCenterId is not set. Make sure the NUXT_VOLUNTEER_CENTER_ID config is set for host ${host}.`,
      )
    }

    volunteerCenterId.value = initialVolunteerCenterId
  }

  if (!volunteerCenterId.value) setInitialCenterId()

  return volunteerCenterId
}

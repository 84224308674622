import * as Sentry from '@sentry/vue'

export default function useSentry() {
  // Workarounds to make sure the app doesn't crash when Sentry is not available,
  // which happens on the server-side, unfortunately...
  const addBreadcrumb = Sentry.addBreadcrumb
    ? Sentry.addBreadcrumb
    : (breadcrumb: any) => {
        console.log(`Logging breadcrumb (Sentry not available):`, breadcrumb)
      }

  const captureException = Sentry.captureException
    ? Sentry.captureException
    : console.error

  const captureMessage = Sentry.captureMessage
    ? Sentry.captureMessage
    : console.log

  return {
    Sentry,
    addBreadcrumb,
    captureException,
    captureMessage,
    // Note: make sure these are only used on the client-side,
    // otherwise they'll fail for the same reason as above.
    // Or, add a wrapper around them.
    setContext: Sentry.setContext,
    setUser: Sentry.setUser,
    setTag: Sentry.setTag,
  }
}

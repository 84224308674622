<script setup lang="ts">
const props = defineProps<{
  error: any
}>()

// const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="absolute flex min-h-screen w-full flex-col overflow-x-hidden">
    <BaseHeading
      as="h2"
      class="my-10 text-center"
    >
      Oeps, er ging iets mis! ☹️
    </BaseHeading>

    <TairoError :error="props.error" />

    <TairoPanels />
  </div>
</template>

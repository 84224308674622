// For Gelderse handen, redirect to /gelderse-handen
export default defineNuxtRouteMiddleware((to) => {
  // Uggly, but this is temporary anyway.
  let host: string | undefined = ''
  if (process.server) {
    // for 3.0.0.rc_vercions: host = nuxtApp.ssrContext.req.headers.host
    // UPD 27.01.23:
    host = useNuxtApp().ssrContext?.event.node.req.headers.host
  }
  else {
    host = window.location.host
  }

  const isOnGelderseHanden = host == 'www.geldersehanden.nl'

  if (isOnGelderseHanden && to.path != '/gelderse-handen') {
    return navigateTo('/gelderse-handen')
  }
})
